<template>
  <div class="shop-events-view">
    <div class="container mb-4" v-if="inited">
      <ShopEventList :list="list"></ShopEventList>
    </div>
  </div>
</template>

<script>
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import { mapActions, mapGetters } from 'vuex';
import ShopEventList from '../../components/loopii/ShopEventList.vue';

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'ShopEventsView',
  data() {
    return {
      inited: false,
      list: [],
    };
  },
  props: {
    baSerial: {
      type: Number,
      required: true,
    }
  },
  components: {
    ShopEventList,
  },
  computed: {
		... mapGetters(['hasToken']),
	},
  watch: {
    
  },
  mounted() {
    try {
      this.handleAsyncTask(async () => {
        const data = await this.$store.dispatch('api/getReviewEventListPromise');
        for (const ba of data) {
          if (ba.businessAccountSerial === this.baSerial) {
            for (const event of ba.events) {
              this.list.push(event);
            }
            break;
          }
        }
        this.inited = true;
      });
    } catch (error) {
      console.error(error);
      this.showMsgModal(error);
    }
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
    
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
</style>